<template>
  <footer class="main-footer">
    <div class="wrap">
      <BrandLogo />
      <LinksList :orientation="isPhone?'vertical':'horizontal'" :links="links" class="links" />
      <div v-if="!$store.getters['scripts/getMerchantName']" class="socials">
        <a href="https://twitter.com" target="_BLANK" aria-label="Twitter link">
          <TwitterIcon />
        </a>
        <a href="https://www.linkedin.com/company/docubizfr/" target="_BLANK" aria-label="LinkedIn DocuBiz">
          <LinkedInIcon />
        </a>
        <a href="https://www.facebook.com/DocuBiz-104190421717273" target="_BLANK" aria-label="Facebook DocuBiz">
          <FacebookIcon />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import BrandLogo from './parts/BrandLogo.vue'
import LinksList from './parts/LinksList.vue'
import FacebookIcon from '@/assets/svg/parts/socials/facebook.svg'
import TwitterIcon from '@/assets/svg/parts/socials/twitter.svg'
import LinkedInIcon from '@/assets/svg/parts/socials/linkedin.svg'
import deviceInfo from '@/mixins/deviceInfo'

export default {
  name: 'MainFooter',
  components: {
    BrandLogo, LinksList, FacebookIcon, TwitterIcon, LinkedInIcon
  },
  mixins: [deviceInfo],
  data () {
    return {
      links: [
        { title: 'CGV', link: '/cgv' },
        { title: 'Mentions Légales', link: '/mentions-legales' },
        { title: 'Contact', link: '/contact' },
        { title: 'Annuaire', link: '/annuaire' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.main-footer {
  max-height: 70px;
  height: 100vh;
  position: relative;
  border-top: 1px solid var(--separator);
  .wrap {
    max-width: 1190px;
    margin: 0 auto;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    height: 100%;
  }
  @include tablet-landscape {
    & {
      max-height: 408px;
      height: 100%;

      .wrap {
        @include flex-line-between;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
        gap: 40px;
      }

      .links {
        gap: 30px;
      }

      .socials {
        gap: 16px;

        a {
          width: 40px;
          height: 40px;
          border-radius: 40px;

          svg {
            max-height: 22px;
            max-width: 24px;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  @include phone {
    & {
      .wrap {
        padding-top: 30px;
        padding-bottom: 50px;
        gap: 50px;
      }
    }
  }
}

.links {
  gap: 24px;
}

.socials {
  @include flex-line-center;
  gap: 10px;

  a {
    @include flex-line-center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: var(--separator);
    --svg-color: var(--paragraph);

    transition: all 0.25s ease;
    outline-offset: 6px;
    outline: 1px transparent solid;

    &:focus-visible {
      outline-offset: 0px;
      outline: 1px var(--hovered-brand) solid;
      --svg-color: var(--hovered-brand);
    }

    &:hover {
      --svg-color: var(--hovered-brand);
    }
  }
}
</style>

<style lang="scss">
.main-footer .socials a {
  svg,
  path {
    transition: all 0.25s ease;
  }
}
</style>
