// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-footer .socials a path,.main-footer .socials a svg{transition:all .25s ease}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet-landscape": "1160px",
	"tablet-portrait": "900px",
	"phone": "640px"
};
module.exports = ___CSS_LOADER_EXPORT___;
